import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {RegisterInterestComponent} from './register-interest/register-interest.component';
import {LegalComponent} from './legal/legal-component';
import {DeleteAccountComponent} from './delete-account/delete-account.component';

const routes: Routes = [
  {
    path: 'legal-stuff',
    component: LegalComponent
  },
  {
    path: 'delete-my-account',
    component: DeleteAccountComponent
  },
  {
    path: '',
    component: RegisterInterestComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
