import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatCheckboxModule, MatButtonModule, MatInputModule, MatFormFieldModule, MatRadioModule, MatCardModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RegisterInterestComponent } from './register-interest/register-interest.component';
import { AppRoutingModule } from './app-routing.module';
import { AppRootComponent } from './root/app-root.component';
import {LegalComponent} from './legal/legal-component';
import {ConfigServiceProvider} from './config/config.service.provider';
import {ConfigService} from './config/config.service';
import { DeleteAccountComponent } from './delete-account/delete-account.component';

@NgModule({
  declarations: [
    RegisterInterestComponent,
    AppRootComponent,
    LegalComponent,
    DeleteAccountComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatRadioModule,
    MatCardModule,
    FlexLayoutModule,
    AppRoutingModule,
    ReactiveFormsModule
  ],
  providers: [
    { provide: APP_INITIALIZER,
      useFactory: ConfigServiceProvider.configServiceProvider,
      deps: [ConfigService],
      multi: true
    },
      ],
  bootstrap: [AppRootComponent]
})
export class AppModule { }

