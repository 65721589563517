import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss']
})
export class DeleteAccountComponent implements OnInit {

  public xHandle: string;
  public submitted: boolean;

  constructor() { }

  ngOnInit() {
  }

  delete() {
    this.submitted = true;
  }

}
