import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {retry} from 'rxjs/operators';
import {ConfigService} from '../config/config.service';
import {Organisation} from './models/register-interest.model';

@Injectable({
  providedIn: 'root'
})
export class RegisterInterestService {

  private httpOptions: any;
  private readonly postInterestOptionsUrl: string;

  constructor(private http: HttpClient,
              private configService: ConfigService) {

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })
    };

    this.postInterestOptionsUrl = this.configService.getEndpoints().floqy.api.client.newClient;
  }

  registerInterest(organisation: Organisation): void {
    this.postRegisterInterest(organisation, this.postInterestOptionsUrl)
      .subscribe();
  }

  private postRegisterInterest(organisation: Organisation, endpoint: string): Observable<any> {
    return this.http.post<Organisation>(endpoint, organisation, this.httpOptions)
      .pipe(retry(3));
  }

}
