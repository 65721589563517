import { Component, OnInit } from '@angular/core';
import { RegisterInterestService } from './register-interest.service';
import {Organisation} from './models/register-interest.model';

@Component({
  selector: 'app-register-interest',
  templateUrl: './register-interest.component.html',
  styleUrls: ['./register-interest.component.scss']
})
export class RegisterInterestComponent implements OnInit {

  public organisation: Organisation = new Organisation();
  public submitted: boolean;

  constructor(private service: RegisterInterestService) { }

  ngOnInit() {
  }

  register() {
    if (!this.organisation.signUpEmail || this.organisation.signUpEmail.trim().length === 0) {
      return;
    }

    this.service.registerInterest(this.organisation);

    this.submitted = true;
  }

  watchSubmit(event) {
    if (event.keyCode === 13) {
      this.register();
    }
  }

}
