export interface Endpoints {
  floqy: Floqy;
}

export interface Floqy {
  api: Api;
}

export interface Api {
  client: Client;
  base: string[];
}

export interface Client {
  newClient: string;
}

export class AppSettings {
  endpoints: Endpoints = {
    floqy: {
      api: {
        base: [
          ''
        ],
        client: {
          newClient: '',
        }
      }
    }
  };
}




